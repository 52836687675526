import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import XYZ from 'ol/source/XYZ';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Circle, Fill, Stroke, Style, Text, Icon} from 'ol/style';
import Overlay from 'ol/Overlay';
import GPX from 'ol/format/GPX';
import WKT from 'ol/format/WKT';
import {wkt, wkt2, wkt3} from './wkt.js';
import {points, centers} from './points.js';
import arc from 'arc';
import LineString from 'ol/geom/LineString';
import '@fortawesome/fontawesome-free/css/all.css';
import {inherits} from 'ol/util';
import {defaults as defaultControls, Control, ZoomToExtent} from 'ol/control';
import Coordinate from 'ol/coordinate';
import Collection from 'ol/Collection';


var rasterLayer = new TileLayer ({
    source: new XYZ ({
        //url: 'http://tiles.maps.sputnik.ru/{z}/{x}/{y}.png',
        //attributions: "<a href='http://maps.sputnik.ru/'>Спутник</a> | &copy; Ростелеком | &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
        url: 'http://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attributions: "Map data: © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> contributors, under ODbL"
    })
});


/* Code Features */
var features = [];

for (var i = 0; i < points.length; i++) {
    features[i] = new Feature ({
      geometry: new Point (fromLonLat(points[i].coord)),
      name: points[i].name,
      img: points[i].img,
      date: points[i].date
    });
}

var source = new VectorSource ({
    features: features
})

var clusterSource = new Cluster({
    distance: 40,
    source: source
});

var styleCache = {};
var vectorLayer = new VectorLayer({
    source: clusterSource,
    style: function(feature) {
        var size = feature.get('features').length;
        var iconText = size > 1 ? size.toString() : '';
        var style = styleCache[size];
        if (!style) {
            style = new Style({
                image: new Circle({
                radius: 10,
                stroke: new Stroke({
                    color: '#0066cc'
                }),
                fill: new Fill({
                    color: 'rgba(0,102,204,0.4)'
                })
              }),
              text: new Text({
                text: iconText,
                fill: new Fill({
                    color: '#fff'
                })
              })
            });
            styleCache[size] = style;
            }
        return style;
    }        
});


/* Track Features */
var vectorLayerTracks = new VectorLayer({
        source: new VectorSource ({
          url: 'gpx/bike.gpx',
          format: new GPX()
        }),
        style: new Style({
          stroke: new Stroke({
            color: '#f00',
            width: 2
          })
        })
      });
      
var vectorLayerTracks2 = new VectorLayer({
        source: new VectorSource ({
          url: 'gpx/scooter.gpx',
          format: new GPX()
        }),
        style: new Style({
          stroke: new Stroke({
            color: '#0f0',
            width: 2
          })
        })
      });
      


var format = new WKT();
var featuresWKT = format.readFeature(wkt, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    });
    
var sourceWKT = new VectorSource ({
    features: [featuresWKT]
})

var vectorWKT = new VectorLayer({
        source: sourceWKT,
        style: new Style({
          stroke: new Stroke({
            color: '#009900',
            width: 1
          })
        }),
        visible: false
});

var format2 = new WKT();
var featuresWKT2 = format.readFeature(wkt2, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    });
    
var sourceWKT2 = new VectorSource ({
    features: [featuresWKT2]
});

var vectorWKT2 = new VectorLayer({
        source: sourceWKT2,
        style: new Style({
          stroke: new Stroke({
            color: '#0066cc',
            width: 1
          })
        }),
        visible: false
});

var format3 = new WKT();
var featuresWKT3 = format.readFeature(wkt3, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
    });
    
var sourceWKT3 = new VectorSource ({
    features: [featuresWKT3]
});

var vectorWKT3 = new VectorLayer({
        source: sourceWKT3,
        style: new Style({
          stroke: new Stroke({
            color: '#0099ff',
            width: 1
          })
        }),
        visible: false
});


//Arc
var start = { x: 24.9685, y: 60.3159};
var end = { x: 12.2539, y: 41.7964 };

var arcGenerator = new arc.GreatCircle(start, end);
var arcLine = arcGenerator.Arc(100, {offset: 10});
           
if (arcLine.geometries.length === 1) {
  var line = new LineString(arcLine.geometries[0].coords);
  line.transform('EPSG:4326', 'EPSG:3857');

  var featureArc = new Feature({
    geometry: line
  });
}

var sourceArc = new VectorSource ({
  features: [featureArc]
})

var vectorArc = new VectorLayer({
  source: sourceArc,
  visible: false
});

//Arc2
var start2 = { x: 30.2654, y: 59.7997};
var end2 = { x: 40.7135, y: 64.5967 };

var arcGenerator2 = new arc.GreatCircle(start2, end2);
var arcLine2 = arcGenerator2.Arc(100, {offset: 10});
           
if (arcLine2.geometries.length === 1) {
  var line2 = new LineString(arcLine2.geometries[0].coords);
  line2.transform('EPSG:4326', 'EPSG:3857');

  var featureArc2 = new Feature({
    geometry: line2
  });
}

var sourceArc2 = new VectorSource ({
  features: [featureArc2]
})

var vectorArc2 = new VectorLayer({
  source: sourceArc2,
  visible: false
});




/* Popup Overlay */
var container = document.getElementById('popup');
var content = document.getElementById('popup-content');
var closer = document.getElementById('popup-closer');

var overlay = new Overlay({
    element: container,
    autoPan: true,
    autoPanAnimation: {
        duration: 250
    }
});

closer.onclick = function() {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};


/* View */
var view = new View({
    center: fromLonLat([30.1630, 59.9505]),
    zoom: 10
  });


//Controls
window.app = {};
var app = window.app;

app.LayerControl = function(id, icon, classname, visible) {
        
        var button = document.createElement('button');
        button.innerHTML = '<i class="fas ' + icon + '"></i>';
        if(visible === true) {
            button.className = 'btn btn-primary';
        } else {
            button.className = 'btn btn-default';
        }
        button.id = id;

        var element = document.createElement('div');
        element.className = 'layer-switcher ol-unselectable ol-control ' + classname;
        element.appendChild(button);

        Control.call(this, {
          element: element
        });

      };
      inherits(app.LayerControl, Control);


          


/* Map */
const map = new Map({
  target: 'map',
  layers: [
    rasterLayer,
    vectorLayer,
    vectorLayerTracks,
    vectorLayerTracks2,
    vectorWKT,
    vectorWKT2,
    vectorWKT3,
    vectorArc,
    vectorArc2
  ],
  overlays: [overlay],
  view: view,
  controls: defaultControls({
          attributionOptions: {
            collapsible: false
          }
        }).extend([
          new app.LayerControl('layer1', 'fa-shoe-prints', 'btn-first', true),
          new app.LayerControl('layer2', 'fa-bicycle', 'btn-second', true),
          new app.LayerControl('layer3', '', 'btn-third', true),
          new app.LayerControl('layer4', 'fa-car', 'btn-4th', false),
          new app.LayerControl('layer5', 'fa-bus', 'btn-5th', false),
          new app.LayerControl('layer6', 'fa-plane', 'btn-6th', false),
          new app.LayerControl('layer7', 'fa-ship', 'btn-7th', false)
        ]),
});

// display popup on click
map.on('click', function(evt) {
    var feature = map.forEachFeatureAtPixel(evt.pixel, function(feature) {
        return feature;
    });
        
    if (feature) {
        var coordinates = feature.getGeometry().getCoordinates();
        var pointerCoordinates = map.getCoordinateFromPixel(evt.pixel);
        var date;
        var finalDate;
        if(feature.values_.features) {
            //if cluster
            if(feature.values_.features.length > 1) {
                view.animate({center: coordinates, zoom: view.getZoom() + 1, duration: 500});
            } else {
                //if single point
                overlay.setPosition(coordinates);
                date = feature.values_.features[0].get('date');
                content.innerHTML = feature.values_.features[0].get('name') 
                      + "<br /><span class='popup-date'>" + date
                      + "</span><br /><img class='popup-img' width='100%' src='img/" 
                      + feature.values_.features[0].get('img') + ".jpg' alt='' />";
            }
        } else {
            //if track
            if (feature.values_.geometry.layout === 'XY') {
                //if WKT
                overlay.setPosition(undefined);
            } else {
                //var coordTrack = coordinates[0][0];
                overlay.setPosition(pointerCoordinates);
                var parts = feature.get('name').split("#");
                date = parts[0].split('_');
                finalDate = date[2] + "." + date[1] + "." + date[0];
                content.innerHTML = parts[1] + "<br /><span class='popup-date'>"
                        + finalDate
                        + "</span><br /><img class='popup-img' width='100%' src='img/" 
                        + parts[0] + ".jpg' alt='' />";
            }
        }
          
    } else {
        overlay.setPosition(undefined);
    }
});
      
// change mouse cursor when over marker
map.on('pointermove', function(e) {
    var pixel = map.getEventPixel(e.originalEvent);
    var hit = map.hasFeatureAtPixel(pixel);
    document.body.style.cursor = hit ? 'pointer' : '';
});
      


/* Buttons */

// Callback for City Buttons Clicks
var getCenters = function(a) {
    return function(event) {
      event.preventDefault();
      flyTo(fromLonLat(a), function() {});
  };
};

// Callback for Layer Buttons Clicks
var getLayers = function(id, layerName) {
    return function(event) {
      event.preventDefault();
      if (layerName.getVisible() === true) {
        layerName.setVisible(false);
        document.getElementById(id).classList.remove("btn-primary");
        document.getElementById(id).classList.add("btn-default");
      } else {
        layerName.setVisible(true);
        document.getElementById(id).classList.remove("btn-default");
        document.getElementById(id).classList.add("btn-primary");
      }
  };
};


function onClick(id, callback) {
  if (document.getElementById(id)) {
    document.getElementById(id).addEventListener('click', callback);
  }
}

for (var i = 0; i < centers.length; i++) {
  onClick('move-' + centers[i].name, getCenters(centers[i].coord));
}

onClick('layer1', getLayers('layer1', vectorLayer));
onClick('layer2', getLayers('layer2', vectorLayerTracks));
onClick('layer3', getLayers('layer3', vectorLayerTracks2));
onClick('layer4', getLayers('layer4', vectorWKT));
onClick('layer5', getLayers('layer5', vectorWKT2));
onClick('layer6', getLayers('layer6', vectorArc));
onClick('layer6', getLayers('layer6', vectorArc2));
onClick('layer7', getLayers('layer7', vectorWKT3));

function flyTo(location, done) {
    var duration = 2000;
    var zoom = view.getZoom();
    var parts = 2;
    var called = false;
    function callback(complete) {
        --parts;
        if (called) {
            return;
        }
        if (parts === 0 || !complete) {
            called = true;
            done(complete);
        }
    }
    view.animate({
        center: location,
        duration: duration
    }, callback);
    view.animate({
        zoom: zoom - 1,
        duration: duration / 2
    }, {
        zoom: zoom,
        duration: duration / 2
    }, callback);
}

document.getElementById('qty').innerHTML = "Пешие: " + points.length;
document.getElementById('qty_bike').innerHTML = "Вело: 71";
document.getElementById('qty_scooter').innerHTML = "Самокат: 30";
//document.getElementById('qty_bike').innerHTML = "Пешие: " + points.length;


//var gfeatures = new GPX()
//var counter = 0;
//var scooterObject = vectorLayerTracks2.getSource().featuresRtree_.items_;
//var scooterObject = vectorLayerTracks2.getSource();
//for (var key in scooterObject) {
  //console.log(key); //counter++;
//}

//console.log(scooterObject);
//console.log(Object.keys(scooterObject));
//Object.getOwnPropertyNames
